import React from 'react';

interface Props {
  sets: Record<string, string[]>;
}

export const SetViewer: React.FC<Props> = ({ sets }) => (
  <div className="set-viewer">
    <h2>Redis Sets</h2>
    {Object.entries(sets).map(([key, members]) => (
      <div key={key} className="set-container">
        <h3>{key}</h3>
        <ul>
          {members.map((member, index) => (
            <li key={`${key}-${index}`}>{member}</li>
          ))}
        </ul>
      </div>
    ))}
  </div>
); 