import React, { useEffect, useState } from 'react';
import './App.css';
import { MemoryUsage } from './components/MemoryUsage';
import { SetViewer } from './components/SetViewer';
import { SetAdder } from './components/SetAdder';
import { redisClient, RedisInfo } from './services/redis';

function App() {
  const [redisInfo, setRedisInfo] = useState<RedisInfo>({ usedMemoryMB: 0, sets: {} });
  const [error, setError] = useState<string | null>(null);

  const fetchRedisInfo = async () => {
    try {
      const info = await redisClient.getInfo();
      setRedisInfo(info);
      setError(null);
    } catch (err) {
      setError('Failed to fetch Redis information');
      console.error('Error fetching Redis info:', err);
    }
  };

  useEffect(() => {
    console.log('API URL:', process.env.REACT_APP_API_URL); // Debug log
    fetchRedisInfo();
    const interval = setInterval(fetchRedisInfo, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleAddToSet = async (key: string, value: string) => {
    try {
      await redisClient.addToSet(key, value);
      await fetchRedisInfo();
      setError(null);
    } catch (err) {
      setError('Failed to add value to set');
      console.error('Error adding to set:', err);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Redis Dashboard</h1>
        <p>API URL: {process.env.REACT_APP_API_URL || 'Using default'}</p>
      </header>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <main>
        <MemoryUsage usedMemoryMB={redisInfo.usedMemoryMB} />
        <SetAdder onAdd={handleAddToSet} />
        <SetViewer sets={redisInfo.sets} />
      </main>
    </div>
  );
}

export default App;
