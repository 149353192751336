import axios from 'axios';

export interface RedisInfo {
  usedMemoryMB: number;
  sets: Record<string, string[]>;
}

class RedisClientService {
  private baseUrl: string;

  constructor() {
    // In production, the API is served from the same host
    const apiHost = process.env.NODE_ENV === 'production' 
      ? window.location.origin 
      : process.env.REACT_APP_API_URL || 'http://localhost:8080';
    
    this.baseUrl = `${apiHost}/api`;
    console.log('API Base URL:', this.baseUrl); // Debug log
  }

  async getInfo(): Promise<RedisInfo> {
    try {
      const response = await axios.get(`${this.baseUrl}/redis/info`);
      return response.data;
    } catch (error) {
      console.error('Error fetching Redis info:', error);
      throw error;
    }
  }

  async addToSet(key: string, value: string): Promise<void> {
    try {
      await axios.post(`${this.baseUrl}/redis/sadd`, { key, value });
    } catch (error) {
      console.error('Error adding to set:', error);
      throw error;
    }
  }
}

export const redisClient = new RedisClientService();