import React, { useState } from 'react';

interface Props {
  onAdd: (key: string, value: string) => Promise<void>;
}

export const SetAdder: React.FC<Props> = ({ onAdd }) => {
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (key && value) {
      await onAdd(key, value);
      setValue('');  // Clear value after adding
    }
  };

  return (
    <div className="set-adder">
      <h2>Add to Set</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Set Key:
            <input
              type="text"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              placeholder="Enter set key"
            />
          </label>
        </div>
        <div>
          <label>
            Value:
            <input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Enter value to add"
            />
          </label>
        </div>
        <button type="submit">Add to Set</button>
      </form>
    </div>
  );
}; 