import React from 'react';

interface Props {
  usedMemoryMB: number;
}

export const MemoryUsage: React.FC<Props> = ({ usedMemoryMB }) => (
  <div className="memory-usage">
    <h2>Memory Usage</h2>
    <div className="memory-value">{usedMemoryMB.toFixed(2)} MB</div>
  </div>
); 